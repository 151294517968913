<template>
  <div class="settings">
    <Header
      :back="true"
      :text="$t('Components.Settings.settings')"
      :title="$t('Components.Settings.general')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Settings.general') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <!-- Units -->
        <router-link :to="{name: 'settings-units'}">
          <div class="settings_link--container">
            <div class="content-container">
              <RoundResourceIcon
                :src="UNIT_ICON_PATH"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ $t('Components.Settings.units') }}
              </h6>
            </div>
            <div class="custom-area">
              <span class="pre-info settings_link--text">{{ currentMeasurmentsSystem }}</span>
              <icon
                icon="chevron-right"
                class="chevron settings_icon--right "
              />
            </div>
          </div>
        </router-link>

        <!-- Locale -->
        <div class="settings_info--container">
          <div class="content-container">
            <RoundResourceIcon
              :src="LANGUAGE_ICON_PATH"
              type="settings reverse"
            />
            <h6 class="setting_link--text">
              {{ $t('Components.Settings.language') }}
            </h6>
          </div>
          <div class="custom-area">
            {{ $t('Components.Settings.language_locale') }}
            <icon
              id="tooltip-target-language"
              icon="question-circle"
              class="chevron settings_icon--right"
            />
            <b-tooltip
              target="tooltip-target-language"
              custom-class="bottom"
              triggers="hover"
              placement="bottomleft"
            >
              {{ $t('Components.Settings.language_tooltip') }}
            </b-tooltip>
          </div>
        </div>

        <!-- System Version -->
        <div
          v-if="version && version.length > 0"
          class="settings_info--container"
        >
          <div class="content-container">
            <RoundResourceIcon
              :src="SYSTEM_ICON_PATH"
              type="settings reverse"
            />
            <h6 class="setting_link--text">
              {{ $t('Components.Settings.version') }}
            </h6>
          </div>
          <p class="margin-auto custom-area normal-regular">
            {{ version }}
          </p>
        </div>
      </Card>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
/** State */
import UserState from '@/singletons/user.state.singleton';

/** Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import { BTooltip } from 'bootstrap-vue';
import { version } from '../../../package.json';

const UNIT_ICON_PATH = '/icons/units.svg';
const LANGUAGE_ICON_PATH = '/icons/language.svg';
const SYSTEM_ICON_PATH = '/icons/cog.svg';
const UPDATE_ICON_PATH = '/icons/update_icon.svg';

export default {
  components: {
    RoundResourceIcon,
    Header,
    Card,
    BTooltip,
  },
  data() {
    return {
      UNIT_ICON_PATH,
      LANGUAGE_ICON_PATH,
      SYSTEM_ICON_PATH,
      UPDATE_ICON_PATH,
      version,
      recentlyClicked: false,
    };
  },
  computed: {
    currentMeasurmentsSystem() {
      return UserState?.profile?.MeasurementSystem === 'METRIC'
        ? this.$t('Components.Settings.metric')
        : this.$t('Components.Settings.imperial');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style/settings.scss';

.settings_info--container{
  .margin-auto{
    margin: auto 0;
  }
}

.update-button--wrapper {
  display: flex;
  .update-button {
    margin: 2rem auto;
  }
}
</style>
