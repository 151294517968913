<template>
  <div class="settings">
    <Header
      :back="true"
      :text="$t('Components.Settings.settings')"
      :title="$t('Components.Settings.change_password')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Settings.change_password') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <p class="label normal-semi ">
          {{ $t('Components.Settings.current_password') }}
        </p>
        <input
          v-model="oldPassword"
          class="margin margin-medium-large margin-bottom form-control"
          autocomplete="password"
          required
          type="password"
          name="oldPassword"
          :placeholder="$t('Components.Settings.current_password')"
        >
        <p class="label normal-semi margin margin-medium margin-top">
          {{ $t('Components.Settings.new_password') }}
        </p>
        <input
          v-model="newPassword"
          class="margin margin-medium-large margin-bottom form-control"
          autocomplete="password"
          required
          type="password"
          name="oldPassword"
          :placeholder="$t('Components.Settings.new_password')"
        >
        <p class="label normal-semi margin margin-medium margin-top">
          {{ $t('Components.Settings.confirm_password') }}
        </p>
        <input
          v-model="confirmedPassword"
          autocomplete="password"
          class="form-control"
          required
          type="password"
          name="oldPassword"
          :placeholder="$t('Components.Settings.confirm_password')"
        >
      </Card>
      <Card position="bottom">
        <Button
          class="margin margin-medium margin-top"
          variant="primary"
          type="submit"
          @click="updatePassword()"
        >
          {{ $t('Components.Settings.save') }}
        </Button>
      </Card>
    </div>
  </div>
</template>

<script>
/* Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import Button from '@/components/button/button.vue';

/* Services */
import AuthService from '@/Services/Auth/auth.service';
import CustomEvent from '@/classes/custom.event.class';

export default {
  components: {
    Header,
    Button,
    Card,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmedPassword: '',
      error: '',
      passwordChanged: false,
    };
  },
  methods: {
    async updatePassword() {
      if (this.confirmedPassword === this.newPassword && this.confirmedPassword.length > 0) {
        const success = await AuthService.verifyBasicPassword(this.oldPassword);
        if (success) {
          try {
            await AuthService.changeBasicPassword(this.newPassword);
            this.passwordChanged = true;
            this.error = this.$t('Components.Settings.password_changed');
          } catch (e) {
            this.passwordChanged = false;
            this.error = this.$t('Components.Settings.could_not_update');
          }
        } else {
          this.passwordChanged = false;
          this.error = this.$t('Components.Settings.password_wrong');
        }
      } else {
        this.passwordChanged = false;
        this.error = this.$t('Components.Settings.password_do_not_match');
      }
      const errorType = this.passwordChanged ? 'success' : 'danger';
      const evt = new CustomEvent(this.error, 'global_error_message', errorType);
      evt.dispatch();
    },
  },
};
</script>
